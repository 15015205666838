.notification{
    padding: 20px;
    width: 350px;
    min-height: 30px;
    background-color: #F1F6FD;
    border: 1px solid #A7C5FD;
    border-left: 3px solid #0052EA;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    position: relative;
}