.match{
    padding: 10px;
    /* width: 350px; */
    min-height: 30px;
    background-color: #F1F6FD;
    border: 1px solid #A7C5FD;
    border-left: 3px solid #0052EA;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.heading{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 500;
    margin-left: 10px;
}

.match_info{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.home_team,.away_team{
    width: 100%;
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    gap: 10px;
}

.logo{
    width: 100%;
    object-fit: contain;
    height: 40px;
    filter: grayscale(0.8);
}

.text{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}

.match_time{
    width: 100%;
    margin-left: 10px;
}

.divider{
    width: 100%;
    height: 1px;
    background-color: #A7C5FD;
}

.grid{
    width: 100%;
    display: grid;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
}

.info{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #A7C5FD;
    border: 1px solid #0052EA;
    border-radius: 2px;
    padding: 10px;
}

.btn_container{
    margin-left: 10px;
}

.btn{
    padding: 10px;
    background-color: #0052EA;
    border-radius: 4px;
    border: 2px solid #A7C5FD;
    outline: none;
    color: white;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    transition: all 0.3s;
}

.btn:active{
    transform: scale(1.1);
}

.btn:hover{
    background-color: #0045c4;
}

.pagination{
    width: 100%;
    grid-column: span 3;
}

@media screen and (max-width:1150px){
    .points_container{
        grid-column: span 2;
    }
    .pagination{
        width: 100%;
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .points_container{
        grid-column: span 1;
    }
    .pagination{
        width: 100%;
        grid-column: span 1;
    }
}