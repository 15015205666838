.container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.win,.lose,.draw{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
}

.win{
    color: white;
    background-color: rgb(76, 250, 45);
}

.lose{
    color: white;
    background-color: rgb(236, 49, 49);
}

.draw{
    background-color: rgb(59, 59, 59);
    color: white;
}