.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
}

.line_up_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.line_up_sub_container{
    position: relative;
    width: 350px;
    height: 425px;
    overflow: hidden;
    background: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,1));
}
.ground{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 425px;
    object-fit: fill;
    opacity: 0.75;
}

.sub_container{
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
}

.grid_container{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    align-items: center;
    gap: 5px;
    width: 100%;
}

.grid_sub_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.image_container{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}
.image_container .image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.square{
    position: absolute;
    top: 0;
    right: -16px;
    width: 24px;
    height: 24px;
    /* padding: 1px 4px 1px 4px; */
    background: rgb(248, 20, 20);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square p{
    color: white;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    padding: 0;
}

.square2{
    position: absolute;
    top: 0;
    left: -16px;
    width: 24px;
    height: 24px;
    /* padding: 1px 4px 1px 4px; */
    background: rgb(16, 47, 252);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square2 p{
    color: white;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    padding: 0;
}

.player_name p{
    color: white;
    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70px;
    margin: 0;
    padding: 0;
}

.point{
    color: white;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    padding: 0;
}

.indicate{
    color: white;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    padding: 0;
}

.btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.grid_col_2{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: center; */
    align-items: flex-start;
    gap: 30px;
    padding: 20px 10px 20px 10px;
}

@media screen and (max-width:800px) {
    .grid_col_2{
        grid-template-columns: 1fr;
    }
}
