.container{
    width: 100%;
    min-height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading{
    width: 200px;
    height: auto;
    object-fit: contain;
}

.loading_text{
    width: 200px;
    height: auto;
    object-fit: contain;
}