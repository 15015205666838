.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.btn{
    padding: 8px 10px 8px 10px;
    outline: none;
    border: none;
    box-shadow: 0 14px 50px rgba(0,0,0,0.3);
    /* background-color: #FF2929; */
    background-color: #2978ff;
    color:white;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
}
.btn:hover{
    background-color: #0858e2;
}
.btn:active{
    transform: scale(1.1);
}

.points_container{
    grid-column: span 3;
}

.pagination{
    width: 100%;
    grid-column: span 3;
}

@media screen and (max-width:1150px){
    .points_container{
        grid-column: span 2;
    }
    .pagination{
        width: 100%;
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .points_container{
        grid-column: span 1;
    }
    .pagination{
        width: 100%;
        grid-column: span 1;
    }
}