.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
    overflow-x: hidden;
}

.grid_col_2{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: center; */
    align-items: flex-start;
    gap: 30px;
    padding: 20px 10px 20px 10px;
}

.stats_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}
.teams_info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.home_team_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.away_team_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
}
.team_logo{
    width: 50px;
    height: 50px;
    object-fit: fill;
}
.name{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}

.stats_sub_container{
    width: 100%;
}

.line_up_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.home_team_info{
    width: 350px;
    background: #073715;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 0 10px 0 10px; */
}

.home_team_logo{
    width: 30px;
    height: 30px;
    object-fit: fill;
}

.home_team_info_sub{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.home_team_name,.home_team_formation{
    color: white;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}

.line_up_sub_container{
    position: relative;
    width: 350px;
    height: 850px;
    overflow: hidden;
    background: linear-gradient(to bottom,rgba(0,0,0,1),rgba(0,0,0,1));
}
.ground{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 850px;
    object-fit: fill;
    opacity: 0.75;
    overflow: hidden;
}
.home_team{
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.away_team{
    position: absolute;
    top: 435px;
    left: 0;
    width: 100%;
}

@media screen and (max-width:800px) {
    .grid_col_2{
        grid-template-columns: 1fr;
    }
}