.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.image_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.avatar{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}

.player_name{
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    color: #343A40;
}

.questions_container{
    width: 100%;
    padding: 0 10px 10px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
}

.question_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
}

.label{
    padding: 0;
    margin: 0;
    text-align: left;
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}