.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.button_container{
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
}

.match_container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0 10px 0;
}

.prediction_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 3px;
    padding: 30px 25px 30px 25px;
}

.questions_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}

/* 0052EA dark blue
A7C5FD light blue

F1F6FD faded blue
*/
.question{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    background-color:#F1F6FD ;
    padding: 20px;
    border: 1px solid #A7C5FD;
    border-left: 4px solid #0052EA;
}

.question_title{
    width: 100%;
    text-align: left;
    font-size: 18px;
    color: #343A40;
    font-weight: 500;
    font-family: 'Montserrat',sans-serif;
    padding: 0;
    margin: 0;
}

.answer_message{
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: #343A40;
    font-weight: 400;
    font-family: 'Montserrat',sans-serif;
    padding: 0;
    margin: 10px 0;
}

.btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.grid_col_2{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: center; */
    align-items: flex-start;
    gap: 30px;
    padding: 20px 10px 20px 10px;
}

@media screen and (max-width:800px) {
    .match_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .grid_col_2{
        grid-template-columns: 1fr;
    }
}
