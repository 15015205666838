.heading_container{
    grid-column: span 3;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding: 0 10px 0 10px;
    border-radius: 3px 3px 0 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.heading{
    font-size: 25px;
    color: #343A40;
}


@media screen and (max-width:1150px){
    .heading_container{
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .heading_container{
        grid-column: span 1;
    }
}