.container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.button_container{
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.tab_container{
    width: 100%;
    background-color: #fff;
    min-height: 500px;
    border-radius: 3px;
}

.match_container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px 0 30px 0;
}

.list_container{
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}

.list_sub_container{
    width: 280px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #e5e7eb;
    padding: 10px;
}

@media screen and (max-width:800px) {
    .match_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}