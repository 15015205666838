.message_box{
    grid-column: span 3;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -12px;
    border-radius: 0 0 3px 3px;
    padding: 0 0 10px 0;
}

.message{
    color: #343A40;
    font-size: 20px;
    text-align: center;
}

@media screen and (max-width:1150px){
    .message_box{
        grid-column: span 2;
    }
}

@media screen and (max-width:500px){
    .message_box{
        grid-column: span 1;
    }
}