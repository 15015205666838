.combined_progress_bar_container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.combined_progress_bar {
  display: flex;
  height: 30px;
  width: 80%;
  border-radius: 5px;
  overflow: hidden;
  background-color: #F9FAFB;
  /* backdrop-filter: blur(100%); */
  border: 2px solid #e5e7eb;
}

.progress_segment_container_end{
  width: 50%;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
}

.progress_segment_container_start{
  width: 50%;
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
}

.container{
  margin-top:15px;
}

.text{
  text-align: center;
  margin-bottom: 4px;
}

.right{
  margin-right: 20px;
}

.left{
  margin-left: 20px;
}
