.container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.name,.time{
    color: #343A40;
    font-size: 18px;
    font-family: 'Montserrat',sans-serif;
}