/* Main Body */

.main{
    width: 100%;
    min-height: 100dvh;
    background-color: #FFFEF6;
}

/* Navbar */
.navbar{
    width: 100%;
    padding: 20px 40px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.logo_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 0;
    cursor: pointer;
}
.logo_image_container{
    width: 60px;
    height: 60px;
}

.logo{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo_text{
    text-align: center;
    font-size: 20px;
    font-family: 'Poppins',sans-serif;
    background: linear-gradient(270deg, #41324F 0%, #65A0A5 24.13%, #41324F 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.navigation_links{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.navigation_button{
    padding: 10px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 20px;
    color: #1E1E1E;
    font-family: 'Poppins',sans-serif;
}

/* Section Landing page.. */
.section_landing_page{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: 30px;
    justify-content: flex-start;
    align-items: stretch;
}

/* Section Subscribe */
.section_subscribe{
    width: 100%;
    padding: 10% 30px;
}

.section_subscribe{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
}

.landing_page_heading_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
}

.landing_page_heading{
    font-size: 24px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    text-align: center;
    color: #3E3E3E;
}

.landing_page_paragraph{
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
    text-align: center;
    color: #3E3E3E;
}

/* subscription form.. */
.subscription_form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    /* background: linear-gradient(to bottom,#3E3E3E,#0B2133); */
    background: #fff;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0 10px 24px rgba(0,0,0,0.2);
}

.form_container{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 20px 20px;
}

.grid_menu{
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 1fr;
    /* background: #01AD6F; */
    /* background: #1E1E1E; */
    background-color: #F1F1F1;
    border-radius: 4px;
    overflow: hidden;
}

.grid_menu_item_1,.grid_menu_item_2{
    width: 100%;
    padding: 14px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    /* border: 1px solid #02C57E; */
}

.grid_menu_item_1.active{
        background-color: #01AD6F;
}

.grid_menu_item_2.active{
    background-color: #01AD6F;
}

.grid_menu_text{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: "Poppins",sans-serif;
    /* color:#F1F1F1; */
    color:#1E1E1E;
}

.grid_menu_text.active{
    color: #F1F1F1;
}

.input_field_grid{
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
}

.country_code_field{
    /* width: 100%; */
    background-color: #F1F1F1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    padding: 10px 10px;
}

.country_flag{
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.country_code{
    color: #1E1E1E;
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
}

.number_input_field{
    width: 100%;
    padding: 14px 14px;
    border-radius: 4px;
    /* border: 1px solid black; */
    border: none;
    outline: none;
    background: #F1F1F1;
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
    color: #1E1E1E;
}

.number_input_field::placeholder{
    /* color: #3E3E3E; */
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
}

.number_input_field::-webkit-inner-spin-button,
.number_input_field::-webkit-outer-spin-button{
    -webkit-appearance: none;
}

/* .number_input_field:focus{
    border: none;
    outline: 1px solid black;
    transition: all 0.2s ease-in-out;
} */

.currency_selector_heading_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 14px;
    background: #1E1E1E;
    border-radius: 4px;
    position: relative;
}

.currency_selector_heading_container p{
    font-size: 18px;
    color: #F1F1F1;
    font-family: 'Poppins',sans-serif;
}

.currency_buttons{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}

.currency_button{
    width: 100%;
    border: none;
    outline: none;
    padding: 14px 14px;
    font-size: 16px;
    font-family: 'Poppins',sans-serif;
    border-radius: 4px;
    background-color: #F1F1F1;
    color: #3E3E3E;
}

.active_currency{
    /* background-color: #FFF; */
    /* background-color: #BEEDF6; */
    background: linear-gradient(to right,#83DDF0,#BEEDF6);
    box-shadow: 0 0 0 1px #F1F1F1;
    position: relative;    
}

.selected_icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #01AD6F;
    box-shadow: 0 0 0 1px #01A66A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon{
    color: #FFF;
    font-size: 14px;
}

.back_button{
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background: #1E1E1E;
    border: none;
    outline: 1px solid #3E3E3E;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 14px 10px rgba(0,0,0,0.2);
}

.back_icon{
    font-size: 14px;
    color: #FFF;
}

.next_button{
    width: 100%;
    padding: 14px 14px;
    /* background-color: #1E1E1E; */
    background:radial-gradient(#01AD6F 15%,#01A66A 45%);
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 18px;
    color: #FFF;
    box-shadow: 0 4px 14px rgba(0,0,0,0.2);
}

.section_landing_page_image{
    width: 100%;
}

.landing_page_soccer_image{
    width: 100%;
    height: auto;
    max-height: 800px;
    object-fit: cover;
}

/* section contest styles... */

.section_contest{
    width: 100%;
    padding: 10% 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    justify-content: center;
    align-items: stretch;
    background:linear-gradient(to bottom,#01AD6F,#02C57E);
    /* background: #FF5858; */
}

.contest_image_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contest_image{
    width: 350px;
    height: auto;
    object-fit: contain;
}

.contest_page_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
}

.contest_heading{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: 'Poppins',sans-serif;
    color: #FFF;
}

.list_ul{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    list-style:square;
    margin-left: 10px;
    width: 100%;
}

.list_ul li{
    font-size: 16px;
    color: #F1F1F1;
    font-family: 'Poppins',sans-serif;
}

.list_ul li span{
 font-weight: 800;   
}

/* section prizes styles... */
.section_prizes{
    width: 100%;
    padding: 10% 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    justify-content: center;
    align-items: stretch;
}

.prize_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 14px;
}

.prize_heading{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: 'Poppins',sans-serif;
    color: #3E3E3E;
}

.prize_list_ul{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    list-style:square;
    margin-left: 10px;
    width: 100%;
}

.prize_list_ul li{
    font-size: 16px;
    color: #3E3E3E;
    font-family: 'Poppins',sans-serif;
}

.prize_list_ul li span{
 font-weight: 800;   
}

.prize_image_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prize_image{
    width: 350px;
    height: auto;
    object-fit: contain;
}

/* footer styles... */
.footer{
    width: 100%;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
    background:linear-gradient(to bottom,#01AD6F,#02C57E);
}

.copyright_mark{
    width: 100%;
    font-size: 15px;
    font-family: 'Poppins',sans-serif;
    color: #FFF;
    text-align: center;
}

.subscribed_title{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    color: #3E3E3E;
    width: 100%;
    text-align: center;
}

@media (width<=900px){
    .form_container{
        width: 95%;
    }
}

@media (width<=750px){
    .main *:not(.section_landing_page_image){
        z-index: 2 !important;
    }
    .navigation_links,.navigation_button{
        display: none;
    }
    .section_landing_page{
        grid-template-columns: 1fr;
        position: relative;
        overflow-x: clip;
    }
    .section_landing_page_image{
        position: absolute;
        opacity: 0.8;
        top: -270px;
        right: -100px;
        z-index: 1 !important;
    }
    .landing_page_soccer_image{
        height: 1000px;
    }
    .section_contest{
        grid-template-columns: 1fr;
    }
    .contest_image_container{
        justify-content: center;
    }
    .section_prizes{
        grid-template-columns: 1fr;
    }
}

@media (width<=550px){
    .navbar{
        padding: 20px 14px;
    }
    .section_subscribe{
        width: 100%;
        padding: 10% 14px;
    }
    .section_contest{
        padding: 10% 14px;
    }
    .section_prizes{
        padding: 10% 14px;
    }
    .footer{
        padding: 30px 14px;
    }
    .section_landing_page_image{
        opacity: 0.6;
    }
}

@media (width<=450px){
    .section_landing_page_image{
        top: -270px;
        right: 0px;
    }
}