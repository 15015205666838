.players_sub_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.player_info_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
}

.player_image{
    width: 50px;
    height: 50px;
}

.img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.player_name{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;
}

.text{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    color: #343A40;
}

.highlight{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    color: rgb(238, 34, 34);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.text_danger_glow{
    color: #ff4141;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00, 0 0 80px #f00;
}

.blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }

.underline{
    height: 1px;
    /* background-color: #343A40; */
    border: 1px solid #e5e7eb;
    width: 100%;
}