.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sub_container{
    width: 300px;
    padding: 10px;
}
.panel_header{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.image_container{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
}
.panel_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.grid{
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.text_right{
    color: #343A40;
    font-size: 16px;
    font-family: 'Montserrat',sans-serif;
    text-align: right;
    width: 100%;
}

.message_box{
    grid-column: span 3;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -12px;
    border-radius: 0 0 3px 3px;
    padding: 0 0 10px 0;
}

.message{
    color: #343A40;
    font-size: 20px;
    text-align: center;
}
