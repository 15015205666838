.container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.image_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
}
.player_info_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}
.text{
    color: #343A40;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat',sans-serif;
}